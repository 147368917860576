import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfoCircle, faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { Dialog, Transition } from '@headlessui/react';
import 'components/formio/formio.css';
import { Form, Formio } from '@formio/react';
import './AddEventForm/form.css';
import { useQueryClient } from 'react-query';
import QUERY_KEYS from '../queries/queryKeys';
import { getDateInDDMMYYYY } from '../../constants/DateFunctions';
import { useEventForm } from '../queries/hooks';
import {
  useMutateCartEvents,
  useMutateSubmitTournament,
  useMutateRemoveCartItem,
} from '../queries/mutations';
import { useParams } from 'react-router-dom';
Formio.setBaseUrl(window.location.origin);
const Productitem = ({
  eventName,
  sportName,
  icon,
  img,
  sportCategory,
  ageGroup,
  subCategory,
  gender,
  price,
  status,
  statusDate,
  enabled,
  teamsCreated,
  statusMessage,
  cartOverviewSummary,
  selectedSport,
  setSelectedSport,
  cartSummary,
  refetch,

  sportEventListFilters,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submissionData, setSubmissionData] = useState({});
  const [form, setForm] = useState();
  const params = useParams();
  const [isRemoving, setIsRemoving] = useState(false);
  const { mutateAsync: removeItem } = useMutateRemoveCartItem({
    onSuccess: async () => {
      // typeof onRemove === 'function';

      queryClient.cancelQueries([
        ...QUERY_KEYS.GET_SPORT_EVENT_LIST,
        sportEventListFilters.tournament_id,
        sportEventListFilters.gender,
        sportEventListFilters.age_category,
        sportEventListFilters.sport,
        sportEventListFilters.sport_category,
        sportEventListFilters.weight_category,
      ]);
      queryClient.setQueriesData(
        [
          ...QUERY_KEYS.GET_SPORT_EVENT_LIST,
          sportEventListFilters.tournament_id,
          sportEventListFilters.gender,
          sportEventListFilters.age_category,
          sportEventListFilters.sport,
          sportEventListFilters.sport_category,
          sportEventListFilters.weight_category,
        ],
        function (oldData) {
          console.log(oldData);
          return oldData.map((event) => {
            if (event.sport_event !== eventName) return event;
            return { ...event, status: '', enabled: true };
          });
        }
      );
    },
    onError: (err) => {
      console.debug('Error - ', err);
    },
    onSettled: () => {
      setIsRemoving(false);
      queryClient.invalidateQueries(QUERY_KEYS.GET_SPORT_EVENT_LIST);
      cartOverviewSummary.refetch();
      cartSummary.refetch();
    },
  });
  const queryClient = useQueryClient();
  const removeCartItem = () => {
    setIsRemoving(true);
    removeItem({ sport_event: eventName });
  };
  function closeModal() {
    setIsOpen(false);
    setSubmissionData({});
  }

  function openModal() {
    setIsOpen(true);
  }

  const { refetch: refetchEventFrom } = useEventForm({
    sportEvent: eventName,
    onSuccess: (res) => {
      if (Object.keys(res).length === 0) {
        mutateCart({ sportEvent: eventName });
      } else {
        setForm(res);
        openModal();
      }
    },
    onError: (err) => {
      console.debug('Error - ', err);
    },
  });

  const { mutateAsync: mutateCart, isLoading } = useMutateCartEvents({
    onSuccess: (_res) => {
      closeModal();
      queryClient.setQueriesData(
        [
          ...QUERY_KEYS.GET_SPORT_EVENT_LIST,
          sportEventListFilters.tournament_id,
          sportEventListFilters.gender,
          sportEventListFilters.age_category,
          sportEventListFilters.sport,
          sportEventListFilters.sport_category,
          sportEventListFilters.weight_category,
        ],
        function (oldData) {
          console.log(oldData);
          return oldData.map((event) => {
            if (event.sport_event !== eventName) return event;
            return { ...event, status: 'Added to Cart', enabled: false };
          });
        }
      );
    },
    onError: (err) => {
      console.debug('Error - ', err);
    },
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEYS.GET_SPORT_EVENT_LIST);
      cartOverviewSummary.refetch();
      cartSummary.refetch();
    },
  });

  const { mutateAsync: submitTournament } = useMutateSubmitTournament({
    onSuccess: (_res) => {
      mutateCart({ sportEvent: eventName });
    },
    onError: (err) => {
      console.debug('Error - ', err);
    },
  });

  const submitForm = (data) => {
    submitTournament({
      tournament_id: params.tournament_id,
      formTemplateName: form?.form_template_name,
      formSubmission: data,
    });
  };

  return (
    <div className="px-3 md:px-6 py-3 md:py-5 md:w-auto flex flex-col md:flex-row justify-start border border-gray-200 rounded-lg">
      <img
        className="md:mr-2 w-auto md:w-40 md:h-40 lg:h-24 lg:w-24 xl:h-48 xl:w-48 product-item-img rounded-lg my-auto"
        alt={sportName}
        src={img ? `${img}` : null}
      />
      <div className="hidden md:flex flex-col justify-center mt-4 md:mt-0 md:ml-4 w-full">
        <div className="flex flex-wrap">
          <h2 className=" text-sm md:text-lg mb-1 mr-1">
            <b>
              {sportName} {icon}
            </b>
          </h2>
          <span className="text-sm md:text-sm text-gray-600 mt-1">
            {subCategory ? `(${subCategory})` : null}
          </span>
        </div>
        <div className="text-xxs xl:text-xs flex flex-wrap gap-2 my-1.5">
          {gender ? ( 
            <button
              className={`${
                gender === 'Male' ? 'bg-blue-50' : 'bg-pink-50'
              } rounded px-2 py-0.5 xl:py-1 flex items-center gap-2 `}
            >
              {gender === 'Male' ? (
                <FontAwesomeIcon
                  icon={faMars}
                  className="text-blue-800 w-2.5 h-2.5 xl:w-3 xl:h-3"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faVenus}
                  className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
                />
              )}
              <span className={`${gender === 'Male' ? 'text-blue-800' : 'text-pink-750'}`}>
                {gender}
              </span>
            </button>
          ) : null}
          {ageGroup ? (
            <button className="rounded px-2 py-0.5 xl:py-1 bg-red-50 flex items-center gap-2">
              <FontAwesomeIcon
                icon={faCalendar}
                className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
              />
              <span className="text-pink-750">{ageGroup}</span>
            </button>
          ) : null}
        </div>
        <div className="w-full mt-4 flex flex-col xl:flex-row">
          <div className="flex-3">
            {status === 'Closed' ? null : (
              <>
                <div className="w-full text-blue-600">{teamsCreated}</div>
                <div className="flex gap-1 items-end xl:block">
                  <h3 className="text-base xl:text-lg font-bold">Rs. {price}</h3>
                  <p className="contents text-xxs xl:text-xs text-gray-400">
                    Per {teamsCreated ? 'Team' : 'Player'}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-4 flex w-full flex-end justify-center md:justify-end pr-0 md:pr-4 lg:pr-8">
          {!enabled ? (
            <>
              <div className="hidden xl:block">
                {showEventStatus(status, statusDate, statusMessage, removeCartItem, isRemoving)}
              </div>
              <div className="mt-1 xl:hidden block">
                {showEventStatus(status, statusDate, statusMessage, removeCartItem, isRemoving)}
              </div>
            </>
          ) : (
            <div className="flex justify-end">
              <button
                className=" mt-2 border border-black bg-white py-2 px-4 rounded-lg transition-colors duration-500 hover:text-white hover:bg-black block xl:hidden text-sm xl:text-base"
                onClick={() => refetchEventFrom()}
              >
                <b>{isLoading ? 'Adding...' : 'Add Event'}</b>
              </button>
              <button
                className="mt-auto border border-black bg-white ml-2.5 py-2 px-5 rounded-lg transition-colors duration-500 hover:text-white hover:bg-black hidden xl:block"
                onClick={() => refetchEventFrom()}
              >
                <b>{isLoading ? 'Adding...' : 'Add Event'}</b>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile View */}
      <div className="flex flex-col md:hidden justify-center mt-4 md:mt-0 md:ml-4">
        <div className="flex justify-between">
          <h2 className=" text-sm font-bold mb-1 mr-1">
            {sportName} {icon}
          </h2>
          <div className="">
            <h3 className="text-sm font-bold">₹{price}</h3>
          </div>
        </div>
        <span className="text-xs text-gray-600 mt-1">{subCategory || ' '}</span>
        <div className="text-xxs xl:text-xs flex flex-wrap gap-2 mt-1.5">
          {gender ? (
            <button
              className={`${
                gender === 'Male' ? 'bg-blue-50' : 'bg-pink-50'
              } rounded px-1 py-0.5 xl:py-1 flex items-center gap-1 `}
            >
              {gender === 'Male' ? (
                <FontAwesomeIcon
                  icon={faMars}
                  className="text-blue-800 w-2.5 h-2.5 xl:w-3 xl:h-3"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faVenus}
                  className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
                />
              )}
              <span className={`${gender === 'Male' ? 'text-blue-800' : 'text-pink-750'}`}>
                {gender}
              </span>
            </button>
          ) : null}
          {ageGroup ? (
            <button className="rounded px-1 py-0.5 xl:py-1 bg-red-50 flex items-center gap-1">
              <FontAwesomeIcon
                icon={faCalendar}
                className="text-pink-750 w-2.5 h-2.5 xl:w-3 xl:h-3"
              />
              <span className="text-pink-750">{ageGroup}</span>
            </button>
          ) : null}
        </div>
        <div className="mt-4 flex w-full flex-end justify-center md:justify-end md:pr-4 lg:pr-8">
          {!enabled ? (
            <>
              <div className="mt-1 xl:hidden block">
                {showEventStatus(status, statusDate, statusMessage, removeCartItem, isRemoving)}
              </div>
            </>
          ) : (
            <div className="">
              <button
                className="px-5 border border-black bg-white py-1 rounded-lg transition-colors duration-500 hover:text-white hover:bg-black text-sm"
                onClick={() => refetchEventFrom()}
              >
                <b>{isLoading ? 'Adding...' : 'Add Event'}</b>
              </button>
            </div>
          )}
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 bg-gray-600 bg-opacity-40 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                <div className="flex justify-center flex-col ">
                  <Dialog.Title as="h4" className="mt-2 text-l font-bold text-gray-700">
                    {form?.form_template_name}
                  </Dialog.Title>
                  <Form
                    form={form?.form}
                    submission={{ data: submissionData }}
                    onSubmit={(schema) => {
                      submitForm(schema);
                    }}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Productitem;

const showEventStatus = (status, statusDate, statusMessage, removeCartItem, isRemoving) => {
  switch (status) {
    case 'Participated':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            <b>{status}</b>
          </button>
        </>
      );
    case 'Added to Cart':
      return (
        <>
          {' '}
          <div className="flex flex-col-reverse md:flex-row items-center w-full">
            <div
              onClick={removeCartItem}
              className="text-gray-400 underline font-bold cursor-pointer md:mr-2"
            >
              {!isRemoving ? 'Remove' : 'Removing'}
            </div>
            <button className="mt-auto text-green-450 px-auto cursor-default block xl:hidden text-sm xl:text-base">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
              <b>{status}</b>
            </button>

            <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block whitespace-nowrap">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              <b>{status}</b>
            </button>
          </div>
        </>
      );
    case 'Event is Full':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <b>{status}</b>
          </button>
        </>
      );
    case 'Cart Limit Reached':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <b>{status}</b>
          </button>
        </>
      );
    case 'Maximum limit Reached':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            <b>{status}</b>
          </button>
        </>
      );
    case 'Maximim limit Reached':
      return (
        <>
          <div>
            <button className="mt-auto text-green-450 px-auto py-1 cursor-default block xl:hidden text-sm xl:text-base">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              <b>{status}</b>
            </button>
          </div>
          <button className="mt-auto ml-2.5 w-full text-green-450 px-auto py-2 cursor-default hidden xl:block">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            <b>{status}</b>
          </button>
        </>
      );
    case 'Yet to start':
      return (
        <>
          <div>
            <h3 className=" w-full text-xs md:text-sm xl:text-base w-max font-bold">{status}</h3>
            <span className="contents text-xs text-gray-400">
              {statusMessage} {getDateInDDMMYYYY(statusDate)}
            </span>
          </div>
        </>
      );
    case 'Closed':
      return (
        <>
          <div>
            <h3 className=" w-full text-xs md:text-sm xl:text-base w-max font-bold">
              Registration Closed
            </h3>
            <span className="contents text-xs text-gray-400">
              {statusMessage} {getDateInDDMMYYYY(statusDate)}
            </span>
          </div>
        </>
      );

    default:
      break;
  }
};
